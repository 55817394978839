import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { postData, getData } from '../../../ApiCalls/DataApis';
import Avatar from '@mui/material/Avatar';
import {LightTooltip, MemberTooltip} from '../../../styles/Tooltip'
import { Zoom } from '@mui/material';
import toast from 'react-hot-toast';
import { SearchIcon } from "@heroicons/react/outline"

const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function AddMemberModal({btnType, remoteOpen,membersList, handleCloseAddMemberModal, selectedOrganization, updatedMemberList, addOutsideOrgMember, inviteNewMember}) {
  const isDark = useRecoilValue(darkModeState)
  const [isOpen, setIsOpen] = useState(false)
  const [loadingAddIoTFlowsMember, setLoadingAddIoTFlowsMember] = useState(false)
  const [loadingSendInvite, setLoadingSendInvite] = useState(false)
  const [searchMemberText, setSearchMemberText] = useState('')
  const [filteredAllIoTFlowsMemberList, setFilteredAllIoTFlowsMemberList] = useState([])
  const [addIoTFlowsMember, setAddIoTFlowsMember] = useState([])
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedInviteRole, setSelectedInviteRole] = useState('')
  const [isTypingEmail, setIsTypingEmail] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [openEmailInviteSection, setOpenEmailInviteSection] = useState(false)


  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setSearchMemberText('')
    setLoadingAddIoTFlowsMember(false)
    setAddIoTFlowsMember([])
    setSelectedInviteRole('')
    setSelectedRole('')
    setIsTypingEmail(false)
    setValidEmail(false)
    setOpenEmailInviteSection(false)
    setFilteredAllIoTFlowsMemberList([])
    setLoadingSendInvite(false)
    handleCloseAddMemberModal()
    setIsOpen(false)
  }

  useEffect(() => {
    if(remoteOpen){
      setIsOpen(true)
    }else{
      closeModal()
    }
  },[remoteOpen])

  useEffect(() => {
    if(addOutsideOrgMember){
      setAddIoTFlowsMember(addOutsideOrgMember)
    }

  },[addOutsideOrgMember])

  useEffect(() => {
    if(inviteNewMember){
      setSearchMemberText(inviteNewMember)
      setOpenEmailInviteSection(true)
    }

  },[inviteNewMember])

  useEffect(() => {
    getData(apiLink + '/v1/organizations/roles')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data && data.data){
            setRoles(data.data)
          }
        }
        
      });
  },[])


  useEffect(() => {
    if(searchMemberText && searchMemberText.length > 0){

      // get list of filtered members in organization in order to not use them when trying to add them later
      const filtered = membersList.filter(member => {
        let fullName = member.user_first_name + ' ' + member.user_last_name
        if(fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
          return member
        }
      })

      // Get members from API
      getData(apiLink + '/v1/users/search/containing?q='+ searchMemberText )
      .then(async response => {
        // JSON data parsed by response.json() call
        if(response.ok ){
          const data = await response.json();
          let newList = data.data
          newList.map((list, index) => {
            filtered.map(memberFilter => {
              if(memberFilter.user_username == list.user_username){
                newList.splice(index, 1)
              }
            })
          })
          setFilteredAllIoTFlowsMemberList(newList)
        }
      })



    }else{
      setFilteredAllIoTFlowsMemberList([])
    }
  },[searchMemberText])

  useEffect(() => {
    if(searchMemberText && searchMemberText.length > 0 && Object.keys(filteredAllIoTFlowsMemberList).length == 0){
      if(searchMemberText.includes('@')){
        setIsTypingEmail(true)
        if( emailCheck.test(searchMemberText) ){
          setValidEmail(true)
        }else {
          setValidEmail(false)
        }
      }else{
        setValidEmail(false)
        setIsTypingEmail(false)
        setOpenEmailInviteSection(false)
      }
    }else {
      setValidEmail(false)
      setIsTypingEmail(false)
      setOpenEmailInviteSection(false)
    }
  },[filteredAllIoTFlowsMemberList, searchMemberText])

  const submitAddIoTFlowsMember = () => {
    try {
      if(!loadingAddIoTFlowsMember && addIoTFlowsMember){
        setLoadingAddIoTFlowsMember(true)
        let roleName = ''
        roles.map(role => {
          if(role.role_id == selectedRole){
            roleName = role.role_name
          }
        })
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/members', 
          {'member_username': addIoTFlowsMember.user_username,
            'role_name': roleName})
            .then(async response => {
              const data = await response.json();
              if(response.ok){
                // const data = await response.json();
                setLoadingAddIoTFlowsMember(false)
                updatedMemberList()
                setAddIoTFlowsMember([])
                setSearchMemberText('')
                toast.success('Member has been added')
                closeModal()
              }
              else{
                try{
                  const data = await response.json();
                  setLoadingAddIoTFlowsMember(false)
                  toast.error(data.message)
                }catch (e){
                  toast.error('Something went wrong')
                  setLoadingAddIoTFlowsMember(false)
                }
              }
            })

      }
      
    } catch (error) {
      console.log(error.message)
      setLoadingAddIoTFlowsMember(false)
    }

  }
  const submitSendInvite = () => {
    try {
      if(!loadingSendInvite && openEmailInviteSection && selectedInviteRole && searchMemberText && searchMemberText.length > 0 && validEmail ){
        setLoadingSendInvite(true) 
        let roleName = ''
        roles.map(role => {
          if(role.role_id == selectedInviteRole){
            roleName = role.role_name
          }
        })
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/invite', 
        {'invite_data': {
            'version': '1.1.2',
            'users': [{'email':searchMemberText}],
            'organization_invite': {
              'version': '1.1.2',
              'organization_uuid': selectedOrganization,
              'organization_role_name': roleName,
              // 'projects':[{
              //   'project_uuid': '',
              //   'project_role_name': '',        
              // }],
            }
          },
          'invite_platform':'DASHBOARD'
        })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              toast.success('Invite has been sent')
              setLoadingSendInvite(false) 
              setSearchMemberText('')
              setValidEmail(false)
              setSelectedInviteRole('')
              updatedMemberList()
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                toast.error(data.message);
                setLoadingSendInvite(false) 
                setSearchMemberText('')
                setSelectedInviteRole('')
                setValidEmail(false)
              }catch (e){
                toast.error('Something went wrong');
                setLoadingSendInvite(false)
              }
            }
          })

      }
      
    } catch (error) {
      // console.log(error.message)
      toast.error(error.message);
      setLoadingSendInvite(false)
    }

  }

  const handleSearchMember = (e) => {
    setSearchMemberText(e.target.value)
  }

  const handleAddMember = (member) => {
    setAddIoTFlowsMember(member)
  }

  const handleEmailInvite = () => {
    setOpenEmailInviteSection(true)
  }


  return (
    <div className='scrollbar-hide'>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Remove member</span>
          </motion.button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Remove</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-scroll scrollbar-hide">
            <div className="flex min-h-full overflow-hidden scrollbar-hide items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-lg min-h-[150px] max-h-[800px] scrollbar-hide transform overflow-x-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <div className={`flex mb-1 p-3 border-b-[1px] ${isDark?'border-gray-700':' border-gray-200'}`}>
                    <div
                      className={` text-lg pl-1 font-medium leading-6 ${isDark?'text-gray-100 ':'text-gray-700 '}`}
                      >
                      Add Member
                    </div>
                    <button
                      type="button"
                      className={`ml-auto p-1 rounded-full bg-transparent ${isDark?' text-gray-400 hover:bg-slate-600':' text-gray-600 hover:bg-gray-100'}`}
                      onClick={closeModal}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  {/* Search bar for searching members in org and in all of IoTFlows */}
                  <div className='relative rounded-full w-full px-4 my-2'>
                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                      <SearchIcon className='h-4 w-4 text-gray-400 ' />
                    </div>
                    <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1.5
                                    ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                            value={searchMemberText} 
                            onChange={handleSearchMember}  
                            type='text' 
                            placeholder="Search by username or email, or invite members via email" 
                            />
                  </div>

                  {/* Determine if its email for new user */}
                  {isTypingEmail && !openEmailInviteSection &&
                  // Add Member section
                  <button className={`flex px-5 py-3 mb-4 items-center w-full  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'}`} 
                          onClick={handleEmailInvite} 
                          disabled={!validEmail}>
                        {validEmail? (
                          <div className={`${isDark? 'text-blue-300  ':'text-blue-600 '} border-0 rounded-full  ml-2.5`}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                              </svg>
                          </div>  
                        ):(
                          <div className={`${ isDark? 'text-gray-400  ':'text-gray-500 '} border-0 rounded-full  ml-2.5`}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                          </div>
                        )}
                    
                    <div className='flex flex-col pl-3 grow overflow-hidden scrollbar-hide'>
                      <div className='flex items-baseline'>
                        <div className='max-w-[250px]'>
                          <div className={`text-base font-semibold truncate`}>
                           {validEmail ? (
                            <span className={`${isDark? 'text-gray-300':'text-gray-600'}`}>Invite {searchMemberText}</span>
                           ):(
                            <span className={`${isDark? 'text-gray-400':'text-gray-500'}`}>Keep typing full email</span>
                           )} 
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  }

                  <AnimatePresence exitBeforeEnter>
                    {Object.keys(addIoTFlowsMember).length == 0 && searchMemberText && searchMemberText.length > 0  && filteredAllIoTFlowsMemberList && Object.keys(filteredAllIoTFlowsMemberList).length > 0 &&
                      <motion.div
                          key='allIoTFlowsmembersListSection'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className={`mt-2`}
                        > 

                        {/* List of members */}
                          <div className='flex flex-col h-full w-full'>
                          {filteredAllIoTFlowsMemberList.map((member, index) => (
                              <MemberTooltip  key={`${member.user_username}-iotflows-member`} TransitionComponent={Zoom}
                                title={
                                  <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-900':' bg-slate-50'} `}>
                                    <div className={`w-full text-center py-1.5 rounded-t-lg text-sm ${isDark? ' bg-slate-600 text-gray-400':' bg-slate-200 text-gray-700'}`}>
                                      {/* <span>{member.organization_member_role_name}</span> */}
                                    </div>
                                    <div className='flex px-2 py-2'>
                                      <div>
                                        <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={member.user_image_url} /> 
                                      </div>
                                      <div className='flex flex-col pl-4 justify-between'>
                                          <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                          <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                          <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate truncate`}>{member.user_email}</h3>
                                      </div>
                                    </div>
                                    <div className='flex justify-between px-2 pt-4 pb-2'>
                                      {/* <button
                                        type="button"
                                        className={`rounded-md bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                        onClick={() => handleEditMemberModal(member)}
                                      >
                                        Edit Role
                                      </button> */}
                                      <button
                                        type="button"
                                        className={`rounded-md ml-auto bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                        onClick={() => handleAddMember(member)}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                }
                              >
                            <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center`}>
                                <div>
                                  <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                                </div>
                                <div className='flex flex-col pl-2 grow overflow-hidden scrollbar-hide'>
                                  <div className='flex items-baseline'>
                                    <div className='max-w-[250px]'>
                                      <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                    </div>
                                    <div className='pl-3 max-w-[200px]'>
                                      <h3 className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                    </div>
                                  </div>
                                </div>
                                <div className='w-9 pl-2'>
                                  <button className={`text-xs text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => handleAddMember(member)}>
                                    Add
                                  </button>
                                </div>
                              </div>
                              </MemberTooltip>
                          ))}

                          </div>
                      </motion.div>
                    }

                    {addIoTFlowsMember && Object.keys(addIoTFlowsMember).length > 0 && !openEmailInviteSection &&
                      <motion.div
                        key='addIoTFlowsMember'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='overflow-y-scroll pt-4 min-w-[375px] scrollbar-hide'
                      > 
                        <div className={`flex flex-col pl-4 pr-10`}>
                          <div className='flex'>
                            <div>
                              <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={addIoTFlowsMember.user_image_url} />
                            </div>
                            <div className='flex flex-col pl-4 justify-between'>
                                <span className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{addIoTFlowsMember.user_first_name}&nbsp;{addIoTFlowsMember.user_last_name}</span>
                                <span className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic truncate`}>{addIoTFlowsMember.user_username_public}</span>
                                <span className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate truncate`}>{addIoTFlowsMember.user_email}</span>
                            </div>
                          </div>
                          <div>
                            <h6 className={`text-sm ${isDark? 'text-gray-300':'text-gray-800'} font-light mt-4`}>Select organization role for member</h6>
                          </div>
                          <div className='pl-2'>
                            {roles && Object.keys(roles).length > 0 && 
                              roles.map((role,index) => (
                                <div key={role.role_id} className="form-check mt-2">
                                  <input className={`form-check-input appearance-none rounded-full h-4 w-4 border ${isDark?'bg-slate-500 border-gray-300':'bg-white border-gray-300'} checked:bg-blue-600 checked:border-blue-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                                        type="radio" 
                                        value={role.role_id}
                                        id={role.role_id}
                                        checked={selectedRole == role.role_id}
                                        onChange={(e) => setSelectedRole(e.target.value)}
                                    />
                                    <LightTooltip sx={{maxWidth:'250px', textAlign:'center'}} TransitionComponent={Zoom} title={role.role_description} arrow >
                                      <label className={`form-check-label inline-block text-sm ${isDark?'text-gray-400':'text-gray-600'} `} htmlFor={role.role_id}>
                                        {role.role_name}
                                      </label>
                                    </LightTooltip> 
                                </div>

                              ))
                            }
                          </div>
                        </div>
                        <form >
                          <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                              type="button"
                              className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                              onClick={() => setAddIoTFlowsMember([])}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                              onClick={submitAddIoTFlowsMember}
                              disabled={loadingAddIoTFlowsMember || selectedRole === ''}
                            >
                              Confirm Add
                              {loadingAddIoTFlowsMember && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                            </button>
                          </div>
                        </form>
                      </motion.div>
                    }

                    {openEmailInviteSection && searchMemberText && searchMemberText.length > 0 && Object.keys(addIoTFlowsMember).length == 0 &&
                      <motion.div
                        key='addIoTFlowsMember'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='overflow-y-scroll pt-4 min-w-[375px] scrollbar-hide'
                      > 
                        <div className={`flex flex-col pl-4 pr-10`}>
                          <div className='flex '>
                                <span className={`text-xl ${isDark? 'text-gray-400':'text-gray-800'} font-light truncate`}>{searchMemberText}</span>
                          </div>
                          <div>
                            <h6 className={`text-sm ${isDark? 'text-gray-300':'text-gray-800'} font-light mt-4`}>Select organization role for member</h6>
                          </div>
                          <div className='pl-2'>
                            {roles && Object.keys(roles).length > 0 && 
                              roles.map((role,index) => (
                                <div key={role.role_id} className="form-check mt-2">
                                  <input className={`form-check-input appearance-none rounded-full h-4 w-4 border ${isDark?'bg-slate-500 border-gray-300':'bg-white border-gray-300'} checked:bg-blue-600 checked:border-blue-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                                        type="radio" 
                                        value={role.role_id}
                                        id={role.role_id}
                                        checked={selectedInviteRole == role.role_id}
                                        onChange={(e) => setSelectedInviteRole(e.target.value)}
                                    />
                                    <LightTooltip sx={{maxWidth:'250px', textAlign:'center'}} TransitionComponent={Zoom} title={role.role_description} arrow >
                                      <label className={`form-check-label inline-block text-sm ${isDark?'text-gray-400':'text-gray-600'} `} htmlFor={role.role_id}>
                                        {role.role_name}
                                      </label>
                                    </LightTooltip>
                                </div>

                              ))
                            }
                          </div>
                        </div>
                        <form >
                          <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                              type="button"
                              className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                              onClick={() => setOpenEmailInviteSection(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                              onClick={submitSendInvite}
                              disabled={loadingSendInvite || selectedInviteRole == ''}
                            >
                              Send invite
                              {loadingSendInvite && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                            </button>
                          </div>
                        </form>
                      </motion.div>
                    }
                  </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default AddMemberModal