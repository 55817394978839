import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../../atoms/darkModeState';
import { putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { toast } from 'react-hot-toast';

highchartsMore(Highcharts);


function BasicAreaLineChart({ chartRef, parameter_type, sensor, startRecordingData, isChecked, setAnalyzedData, getSensorList}) {
    const isDark = useRecoilValue(darkModeState);
    const [overallUtilizationOptions, setOverallUtilizationOptions] = useState({
        credits: {enabled: false},
        chart: {
            // zoomType: 'x',
            height: '175px',
            backgroundColor: 'transparent',
            spacingBottom: 0,
            marginBottom: 0,
            marginTop: 0,
        },
        legend: { enabled: false},
        navigator : { enabled : false},
        scrollbar : { enabled : false},
        rangeSelector : {
            enabled: false,
            selected : 5,
            inputEnabled: false
        },
    })
    const [minValue, setMinValue] = useState(0.03);
    const [maxValue, setMaxValue] = useState(0.05);
    const [momentum, setMomentum] = useState(0.1);
    const [initialData, setInitialData] = useState([]);

    useEffect(() => {
        if(sensor && parameter_type){
            if(parameter_type === 'vibration'){
                if(sensor.node_vibration_upper_threshold && sensor.node_vibration_lower_threshold){
                    setMaxValue(sensor.node_vibration_upper_threshold)
                    setMinValue(sensor.node_vibration_lower_threshold)
                    setMomentum(sensor.node_vibration_momentum)
                    setInitialData(sensor.calibration_vibrations)
                }
            }else if(parameter_type === 'acoustic'){
                if(sensor.node_vibration_upper_threshold && sensor.node_vibration_lower_threshold){
                    setMaxValue(sensor.node_acoustic_upper_threshold)
                    setMinValue(sensor.node_acoustic_lower_threshold)
                    setMomentum(sensor.node_acoustic_momentum)
                    setInitialData(sensor.calibration_acoustics)
                }
            }
        }

    }, [sensor])

    
    useEffect(() => {
        if(chartRef){
            setOverallUtilizationOptions({
                credits: {enabled: false},
                chart: {
                    // zoomType: 'x',
                    height: '175px',
                    backgroundColor: 'transparent',
                    spacingBottom: 0,
                    marginBottom: 0,
                    marginTop: 0,
                },
                time: {
                    useUTC: false
                  },
                xAxis: {
                    // type: 'datetime',
                    lineColor: isDark? '#6b7280': '#6b7280',
                    labels: {
                        enabled: false
                        // style: {
                        //     color: isDark? '#d1d5db': '#4b5563'
                        // }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    // title: 'testsdfv', 
                    // align: 'left',
                    opposite: false,
                    labels: {
                        // enabled: false,
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        },
                        // formatter: function () {
                        //     return this.value + '%';
                        //   }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    // min:0,
                    // max:0.1,
                    plotBands: [{
                        color: 'rgba(147, 197, 253, 0.3)', // Color value
                        from: minValue, // Start of the plot band
                        to: maxValue // End of the plot band
                      }],
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                rangeSelector : {
                    enabled: false,
                    selected : 5,
                    inputEnabled: false
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                series: [{
                    type: 'areaspline',
                    name: 'Point',
                    groupPadding: 0,
                    pointPadding: 0,
                    data:initialData
                    // step: true,
                    // data: chartData
                    // data: [
                    //     0.1, 0.3, 0, 0.04, 0.023, 0.0321, 0.056, 0.0566, 0.058, 0.058, 0.08, 0.09, 0.05, 0
                    // ]
                }],
                tooltip: {
                    formatter: function() {
                        return 'Point:'+ this.y 
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            })
        }

      }, [isDark, initialData])

      useEffect(() => {
        //  using this to reset data once the start button is clicked again
        if(startRecordingData){
            setOverallUtilizationOptions({
                credits: {enabled: false},
                chart: {
                    // zoomType: 'x',
                    height: '175px',
                    backgroundColor: 'transparent',
                    spacingBottom: 0,
                    marginBottom: 0,
                    marginTop: 0,
                },
                time: {
                    useUTC: false
                  },
                xAxis: {
                    // type: 'datetime',
                    lineColor: isDark? '#6b7280': '#6b7280',
                    labels: {
                        enabled: false
                        // style: {
                        //     color: isDark? '#d1d5db': '#4b5563'
                        // }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    // title: 'testsdfv', 
                    // align: 'left',
                    opposite: false,
                    labels: {
                        // enabled: false,
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        },
                        // formatter: function () {
                        //     return this.value + '%';
                        //   }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    // min:0,
                    // max:0.1,
                    plotBands: [{
                        color: 'rgba(147, 197, 253, 0.3)', // Color value
                        from: minValue, // Start of the plot band
                        to: maxValue // End of the plot band
                      }],
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                rangeSelector : {
                    enabled: false,
                    selected : 5,
                    inputEnabled: false
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                series: [{
                    type: 'areaspline',
                    name: 'Point',
                    groupPadding: 0,
                    pointPadding: 0,
                    data:[]
                    // step: true,
                    // data: chartData
                    // data: [
                    //     0.1, 0.3, 0, 0.04, 0.023, 0.0321, 0.056, 0.0566, 0.058, 0.058, 0.08, 0.09, 0.05, 0
                    // ]
                }],
                tooltip: {
                    formatter: function() {
                        return 'Point: '+ this.y 
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            })
            
        }

      }, [startRecordingData])

      useEffect(() => {
        if(minValue && maxValue && chartRef){
            chartRef.current.chart.yAxis[0].update({
                plotBands: [{
                    from: minValue, 
                    to: maxValue,
                    color: 'rgba(147, 197, 253, 0.3)',
                }]
             }) 
            
        }
      },[ minValue, maxValue])


    const handleMaxSliderRelease = (e) => {
        if(e.target.value <= minValue){
            // Only changin the min value if the max value is lower and they have released the slider
            setMinValue(e.target.value)
            let data_package_min = null
            let capitalized_min = ''
            if(parameter_type === 'vibration'){
                data_package_min = {"node_vibration_lower_threshold": e.target.value}
                capitalized_min = 'Vibration'
            }else if(parameter_type === 'acoustic'){
                data_package_min = {"node_acoustic_lower_threshold": e.target.value}
                capitalized_min = 'Acoustic'
            }
            if(data_package_min){
                putData(apiLink + `/v1/nodes/${sensor.node_uuid}/${parameter_type}_lower_threshold` , data_package_min)
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                    toast.success(capitalized_min + ' Lower Threshold Updated')
                        getSensorList()
                        if(data && data.data && data.data.calibration_results){
                            setAnalyzedData(data.data.calibration_results)
                        }
                    }
                    else{
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e.message)
                    }
                    }
                })
            }
        }

        let data_package_max = null
        let capitalized_max = ''
        if(parameter_type === 'vibration'){
            data_package_max = {"node_vibration_upper_threshold": e.target.value}
            capitalized_max = 'Vibration'
        }else if(parameter_type === 'acoustic'){
            data_package_max = {"node_acoustic_upper_threshold": e.target.value}
            capitalized_max = 'Acoustic'
        }
        if(data_package_max){
            putData(apiLink + `/v1/nodes/${sensor.node_uuid}/${parameter_type}_upper_threshold` , data_package_max)
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  toast.success(capitalized_max + ' Upper Threshold Updated')
                  getSensorList()
                  if(data && data.data && data.data.calibration_results){
                    setAnalyzedData(data.data.calibration_results)
                    }
                }
                else{
                  try{
                    toast.error(data.message)
                  }catch (e){
                    toast.error(e.message)
                  }
                }
              })
        }
        
      };


    const handleMinSliderRelease = (e) => {
        if(e.target.value >= maxValue){
            // Only changin the max value if the lower value is greater and they have released the slider
            setMaxValue(e.target.value)
            let data_package_max = null
            let capitalized_max = ''
            if(parameter_type === 'vibration'){
                data_package_max = {"node_vibration_upper_threshold": e.target.value}
                capitalized_max = 'Vibration'
            }else if(parameter_type === 'acoustic'){
                data_package_max = {"node_acoustic_upper_threshold": e.target.value}
                capitalized_max = 'Acoustic'
            }
            if(data_package_max){
                putData(apiLink + `/v1/nodes/${sensor.node_uuid}/${parameter_type}_upper_threshold` , data_package_max)
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        if(data && data.data && data.data.calibration_results){
                            setAnalyzedData(data.data.calibration_results)
                        }
                        toast.success(capitalized_max + ' Upper Threshold Updated')
                        getSensorList()
                    }
                    else{
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e.message)
                    }
                    }
                })
            }
        }

        let data_package_min = null
        let capitalized_min = ''
        if(parameter_type === 'vibration'){
            data_package_min = {"node_vibration_lower_threshold": e.target.value}
            capitalized_min = 'Vibration'
        }else if(parameter_type === 'acoustic'){
            data_package_min = {"node_acoustic_lower_threshold": e.target.value}
            capitalized_min = 'Acoustic'
        }
        if(data_package_min){
            putData(apiLink + `/v1/nodes/${sensor.node_uuid}/${parameter_type}_lower_threshold` , data_package_min)
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                  if(data && data.data && data.data.calibration_results){
                        setAnalyzedData(data.data.calibration_results)
                    }
                    toast.success(capitalized_min + ' Lower Threshold Updated')
                    getSensorList()
                }
                else{
                try{
                    toast.error(data.message)
                }catch (e){
                    toast.error(e.message)
                }
                }
            })
        }

        
        
    };

    const handleMomentumSliderRelease = (e) => {

        let data_package_min = null
        let capitalized_mom = ''
        if(parameter_type === 'vibration'){
            data_package_min = {"node_vibration_momentum": e.target.value}
            capitalized_mom = 'Vibration'
        }else if(parameter_type === 'acoustic'){
            data_package_min = {"node_acoustic_momentum": e.target.value}
            capitalized_mom = 'Acoustic'
        }
        if(data_package_min){
            putData(apiLink + `/v1/nodes/${sensor.node_uuid}/${parameter_type}_momentum` , data_package_min)
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    toast.success(capitalized_mom + ' Momentum')
                    getSensorList()
                }
                else{
                try{
                    toast.error(data.message)
                }catch (e){
                    toast.error(e.message)
                }
                }
            })
        }

    }


  return (
    <div className={`flex flex-col lg:flex-row w-full relative`}>
        <div className={`absolute top-0 left-0 w-full h-full z-10 ${!isChecked ? 'backdrop-blur-[2px] bg-slate-900/30' : 'hidden' } rounded-t-lg`} />
        <div className='flex-1'>
            <HighchartsReact
                highcharts={HighchartsStock}
                constructorType={'stockChart'}
                options={overallUtilizationOptions}
                ref={chartRef}
            />

        </div>
        <div className='lg:min-w-[400px] xl:min-w-[500px] flex flex-col justify-center pr-2'>
            <div className='w-full md:flex md:items-center'>
                <div className='flex w-[340px] items-center mr-1'>
                    <label htmlFor="minmax-range-high" className={`block mr-1 text-left leading-6 text-sm font-regular ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                    Upper Threshold:
                    {/* <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{maxValue}</span>  */}
                    </label>
                    <input 
                            type='number' 
                            className={`text-base px-3 py-0.5 font-medium rounded-xl w-20 bg-transparent border-0 focus:ring-[0px] ${isDark? 'text-white focus:bg-slate-700 hover:bg-slate-700' : 'text-gray-600 focus:bg-slate-100'}`}
                            value={maxValue} 
                            onChange={(e) => setMaxValue(e.target.value)}
                            // onChange={handleChangeMaxValueText}
                            onBlur={handleMaxSliderRelease}
                            onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === 'Tab') {
                                handleMaxSliderRelease(event);
                            }
                            }}
                        />

                </div>
                <input id="minmax-range-high" type="range" min="0.001" max="0.7" step="0.0001"
                    className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                    value={maxValue} 
                    disabled={!isChecked}
                    onChange={(e) => setMaxValue(e.target.value)} 
                    onMouseUp={handleMaxSliderRelease}
                />
            </div>
            <div className='w-full mt-1 md:flex md:items-center'>
                {/* <label htmlFor="minmax-range-low" className={`block mb-1 w-[310px] text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                Lower Threshold:<span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{minValue}</span> 
                </label> */}
                <div className='flex w-[340px] items-center mr-1'>
                    <label htmlFor="minmax-range-high" className={`block mr-1 text-left leading-6 text-sm font-regular ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                    Lower Threshold:
                    {/* <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{maxValue}</span>  */}
                    </label>
                    <input 
                            type='number' 
                            className={`text-base px-3 py-0.5 font-medium rounded-xl w-20 bg-transparent border-0 focus:ring-[0px] ${isDark? 'text-white focus:bg-slate-700 hover:bg-slate-700' : 'text-gray-600 focus:bg-slate-100'}`}
                            value={minValue} 
                            onChange={(e) => setMinValue(e.target.value)}
                            // onChange={handleChangeMinValueText}
                            onBlur={handleMinSliderRelease}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === 'Tab') {
                                    handleMinSliderRelease(event);
                                }
                            }}
                        />

                </div>
                <input id="minmax-range-low" type="range" min="0.001" max="0.7" step="0.0001"
                    className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                    value={minValue} 
                    disabled={!isChecked}
                    onChange={(e) => setMinValue(e.target.value)} 
                    onMouseUp={handleMinSliderRelease}
                />
            </div>
            <div className='w-full mt-1 md:flex md:items-center'>
                {/* <label htmlFor="momentum-range" className={`block mb-1 w-[310px] text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                    Momentum:<span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{momentum}</span> 
                </label> */}
                <div className='flex w-[340px] items-center mr-1'>
                    <label htmlFor="minmax-range-high" className={`block mr-1 text-left leading-6 text-sm font-regular ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                    Momentum:
                    {/* <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{momentum}</span>  */}
                    </label>
                    <input 
                            type='number' 
                            className={`text-base px-3 py-0.5 font-medium rounded-xl w-20 bg-transparent border-0 focus:ring-[0px] ${isDark? 'text-white focus:bg-slate-700 hover:bg-slate-700' : 'text-gray-600 focus:bg-slate-100'}`}
                            value={momentum} 
                            onChange={(e) => setMomentum(e.target.value)}
                            onBlur={handleMomentumSliderRelease}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === 'Tab') {
                                    handleMomentumSliderRelease(event);
                                }
                            }}
                        />

                </div>
                <input id="momentum-range" type="range" min="0.001" max="0.3" step="0.001"
                    className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                    value={momentum} 
                    disabled={!isChecked}
                    onChange={(e) => setMomentum(e.target.value)} 
                    onMouseUp={handleMomentumSliderRelease}
                />
            </div>

        </div>
    </div>
  )
}

export default BasicAreaLineChart
