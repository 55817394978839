import React from 'react'
  

function WorkOrderTableView({workOrdersList}) {
  return (
    <div className='overflow-x-auto'>
        <table className="min-w-full border-collapse table-fixed min-w-[800px]">
        <thead>
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 w-40 text-left sticky left-0 z-10 bg-white dark:bg-slate-900">Title</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">ID</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Status</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Priority</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Assigned To</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Asset</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Created By</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Created On</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Updated On</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Due Date</th>
            <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Recurrence</th>
          </tr>
        </thead>
        <tbody>
          {workOrdersList.map((order) => (
            <tr key={order.id} className='border-b-[0.01em]  dark:border-zinc-500  hover:bg-slate-100 dark:hover:bg-slate-800'>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1 sticky left-0 z-10 bg-white dark:bg-slate-900">{order.title}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.id}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.status}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.priority}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.assignedTo}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.asset}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.createdBy}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.createdOn}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.updatedOn}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.dueDate}</td>
              <td className="text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1">{order.recurrence}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WorkOrderTableView