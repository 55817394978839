import { Dialog, Transition, DialogPanel, TransitionChild, DialogTitle } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import GeneralFormInput from '../../forms/GeneralFormInput';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { SearchIcon } from '@heroicons/react/outline';
import ImageZoomCrop from '../ImageZoomCrop';
import { Avatar } from '@mui/material';


function CreateNewTeamModal({btnType, remoteOpenAssetModal,userInfo, updatedTeamsList}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
      teamName: '',
    });
    const [teamImage, setTeamImage] = useState(null)
    const [teamHandle, setTeamHandle] = useState(null)
    const [isUserHandleApproved, setIsUserHandleApproved] = useState(false)
    const [addMembers, setAddMembers] = useState(false);
    const [isTyping, setIsTyping] = useState(true);
    const typingTimer = useRef(null);
    const typingInterval = 500; 

    const [selectedMembers, setSelectedMembers] = useState({})
    const [usersList, setUsersList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    const [activeParticipants, setActiveParticipants] = useState([])
    const [isHighlighted, setIsHighlighted] = useState(null);
    
    useEffect(() => {
      if(userInfo && Object.keys(userInfo).length > 0){
        console.log('userInfo.user_username', userInfo.user_username)
       
        setActiveParticipants(userInfo.user_username)
      }

    }, [userInfo])

    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])

    useEffect(() => {
        return () => {
          clearTimeout(typingTimer.current);
        };
      }, []);
    

    function closeModal() {
      setIsOpen(false)
      setForm({
        teamName: '',
        teamHandle: '',
      })
      setTeamImage(null)
    }

    function openModal() {
      setIsOpen(true)
    }


  // **** Functions for General Input forms **** 
  const handleGeneralTeamFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setForm(updatedForm);

  }



  const submitTeamInfo = () => {
    try {
      if(!loading){
        setLoading(true)
        let membersArray = Object.keys(selectedMembers)
        let memberJson = []
        membersArray.map(member => {
            memberJson.push({
                "username": member,
                "is_owner": false
            })
        })
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/teams', 
            {
                'team_name': form.teamName,
                'team_handle': teamHandle,
                'members': memberJson
            })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              setLoading(false)
              toast.success('Team added')
              updatedTeamsList()
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })
      }
      
    } catch (error) {
      
    }
  }

  const handleUpdateTeamImage = (image) => {
    setTeamImage(image)
  }



  const handleInput = (e) => {
    let handle = e.target.value.toLowerCase().replace(/[^\w\s]/gi, '')
    setTeamHandle(handle)
    if (!isTyping) {
      setIsTyping(true);
    }
    clearTimeout(typingTimer.current);
    // typingTimer.current = setTimeout(handleUserStoppedTyping, typingInterval);
    typingTimer.current = setTimeout(() => {
        // console.log('User has stopped typing for more than 0.5 second.');
        setIsTyping(false);
        getData(apiLink + `/v1/verify_user_organization_handle?user_has_organization_user_handle=${handle}&organization_uuid=${selectedOrganization}`)
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data && data.data){
                if(data.data[0].is_available){
                    setIsUserHandleApproved(true)
                    toast.success('Team handle available')
                }else{
                    setIsUserHandleApproved(false)
                    toast.error('Team handle not available')
                }
            }
            }
            
        });

    }, typingInterval);
  };



  useEffect(() => {
    console.log('selectedOrganization', selectedOrganization)
        if(selectedOrganization && userInfo && Object.keys(userInfo).length > 0){
            updatedMemberList()
        }
  },[selectedOrganization, userInfo])

const updatedMemberList = () => {
    try {

        if(selectedOrganization ){
        //   getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
          getData(apiLink + '/v1/organizations/' + selectedOrganization + '/members')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){

                const updatedMembers = removeUsersFromMembers(data.data, userInfo);

                setUsersList(updatedMembers)
                // setUsersList(data.data)

              }
            }
          }) 
        }
      } catch (error) {
        console.log(error.message)
      }
}

function removeUsersFromMembers(members, usersToRemove) {
    // Create an array of usernames to remove for easy lookup
    console.log('usersToRemove', usersToRemove.user_username)
    const usernamesToRemove = usersToRemove.user_username;
    // const usernamesToRemove = usersToRemove.map(user => user.user_username);
    
    // Filter out members whose username is in the usernamesToRemove array
    return members.filter(member => !usernamesToRemove.includes(member.user_username));
  }
  

useEffect(() => {
    if(searchMemberText  && searchMemberText.length > 0){
      const filtered = usersList.filter(member => {
        let fullName = member.user_first_name + ' ' + member.user_last_name
        if(member.user_username.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
          return member
        }
      })
      setFilteredMemberList(filtered)

    }else{
      setFilteredMemberList([])
    //   setFilteredAllIoTFlowsMemberList([])
    }
  },[searchMemberText])

  const handleAddUser = (e, user) => {
    setSearchMemberText('')

    const itemId = user.user_username
    const newSelectedItems = {...selectedMembers}; // Copy the existing array

    
    const index = Object.keys(newSelectedItems).indexOf(itemId);
    if (index === -1) {
        // Item not found, add it to the array
        newSelectedItems[itemId] = user;
        // newSelectedItems.push(itemId);
    } else {
        // Item found, remove it from the array
        // newSelectedItems.splice(index, 1);
        delete newSelectedItems[itemId]
    }
    let count = Object.keys(newSelectedItems).length
    setSelectedMembers(newSelectedItems);

  }


  const handleRemoveUser = (e) => {
    const key = e.key;
    if ((key === 'Backspace' || key === 'Delete') && !e.ctrlKey && !e.metaKey) {
        if(searchMemberText.length === 0){

            if (isHighlighted) {
               // Call delete function with component reference
            //    let newMembers = selectedMembers.filter((element) => element !== isHighlighted);
            //    setSelectedMembers(newMembers)

               let newSelectedItems = {...selectedMembers}; // Copy the existing array
                delete newSelectedItems[isHighlighted]

                setSelectedMembers(newSelectedItems)
                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
            } else {
                const keys = Object.keys(selectedMembers);
                const lastKey = keys[keys.length - 1];
            //   const lastValue = selectedMembers[selectedMembers.length - 1];
                setIsHighlighted(lastKey);
              
            }
            e.preventDefault(); // Prevent default backspace/delete behavior
          } else {
            setIsHighlighted(null); // Reset highlight if unrelated key is pressed
          }
    }
  };

const removeMember = (member) => {
    if(member){
        let newSelectedItems = {...selectedMembers}; // Copy the existing array
        delete newSelectedItems[member]

        setSelectedMembers(newSelectedItems)
    }
}


const handleSearchMemberText = (e) => {
    setSearchMemberText(e.target.value.replace(/,/g, ''))
}





  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <PlusSmIcon className='btnMainIcon' />
            <span className='btnMainText pl-0.5'>Create Team</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
      }
      {btnType ==='inlineIconBtn' &&
        <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <PlusSmIcon className={`w-5 h-5`}/>
        </button>
      }
      {btnType ==='hideBtn' &&
        <div className='w-0 h-0'>{''}</div>
      }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Create Team
                  </DialogTitle>
                  <AnimatePresence mode='wait'>
                    {!addMembers ? (

                    <motion.div
                      key='teamInfoContainer'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                        <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4'>
                          <div className='text-left mt-2'>
                            {/* <div className='justify-center text-center'>
                              <p className={`text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}>
                                Team Image
                              </p>
                              <ImageZoomCrop currentPicture={teamImage} submitType={'teamImage'} title={'Team Image'} isDisabled={false} isRounded={true} updateInfo={handleUpdateTeamImage} />
                            </div> */}
                            <div className="pt-2 ">
                              <GeneralFormInput label='Team Name' type='text' defaultValue={''} name='teamName' onChange={handleGeneralTeamFormChange} />
                            </div>
                            <div className="pt-2 flex flex-col">
                              <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'teamHandle'}>
                                    Team Handle
                                </label>
                                <div className='flex items-center'>
                                    <input
                                        type="text"
                                        name='teamHandle'
                                        value={teamHandle}
                                        // onChange={(e) => setTeamHandle(e.target.value)}
                                        onInput={handleInput}
                                        className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                        autoFocus // Focus on input upon render
                                    />
                                    {teamHandle && teamHandle.length > 0 && !isTyping && isUserHandleApproved &&
                                    <div className='ml-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-green-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </div>
                                    }
                                    {!isTyping && !isUserHandleApproved &&
                                    <div className='ml-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                        </svg>
                                    </div>
                                    }
                            </div>
                            </div>
                          </div>
                        </div>
                        <form >
                        <div className={`flex p-4 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={closeModal}
                            >
                            Cancel
                            </button>
                            <button
                            type="button"
                            className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent items-center ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} pl-4 pr-1 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                            onClick={() => setAddMembers(true)}
                            disabled={form.teamName.length == 0 || !isUserHandleApproved || teamHandle.length == 0}
                            >
                            {`Add Members`}
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-180" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            </button>
                        </div>
                        </form>
                    </motion.div>
                    ):(
                        <motion.div
                          key='addMembersContainer'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        >
                            <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4 mt-2 mb-4'>
                              <div className={`flex ${isDark?'text-gray-400':'text-gray-500'} absolute top-0 left-2`}>
                                  <div className={`p-1 my-auto rounded-full transition duration-300 ease-in-out ${isDark?'hover:bg-gray-700':'hover:bg-gray-200'} hover:-translate-y-0.5`} >
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                      </svg>
                                  </div>
                              </div>
                              <div className='text-left mt-4'>
                              </div>
                            </div>
                            <motion.div
                                key='selectMembersDiv'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className='relative'
                            >
                                <div className={`sticky top-9 pb-1 px-4 mb-2 ${isDark? 'bg-slate-800 border-slate-700':'bg-slate-100 border-slate-200'} z-10 border-b-[0.01em]`}>
                                    {selectedMembers && Object.keys(selectedMembers).length > 0 ? (
                                        <div className='rounded-full w-full py-2'>
                                            <div className={`flex flex-wrap ${isDark? 'bg-slate-700/50':'bg-slate-100'} rounded-xl px-2 py-1`}>
                                                {Object.keys(selectedMembers).map((member) => (
                                                    <div key={`${member}-selectedMembers`} className={`${member === isHighlighted ? 'bg-blue-600/40':'bg-blue-600/80'} flex text-white rounded-full pr-1 pl-2 py-0 mr-1 my-0.5 items-center font-light`}>
                                                        <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 20, height: 20, }}  src={selectedMembers[member].user_image_url} />
                                                        <span className='px-2'>{member}</span>
                                                        <button 
                                                        onClick={() => removeMember(member)}
                                                        className={`bg-white/50 ${isDark? 'text-slate-700/50':'text-slate-100'} rounded-full h-4 p-0.5`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))} 
                                                <input className={`grow py-0 min-w-[150px] bg-transparent text-gray-600 border-0 border-gray-300 
                                                                ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                                                focus:ring-[0px]  text-sm text-ellipsis `}
                                                    value={searchMemberText } 
                                                    onChange={handleSearchMemberText}
                                                    onKeyDown={handleRemoveUser}
                                                    type='text' 
                                                    />
                                            </div>

                                        </div>
                                    ):(
                                        <div className='relative rounded-full w-full py-2'>
                                            <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                                <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                            </div>
                                            <input className={`border-0 focus:ring-[0px] block w-full pl-8 text-sm rounded-lg text-ellipsis py-2
                                                    ${isDark? 'border-gray-300 bg-gray-100 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400 hover:bg-slate-700/80 focus:bg-slate-700/80 bg-slate-700/50 focus:ring-gray-500':'text-gray-600 focus:bg-slate-100 focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '} `}
                                                value={searchMemberText} 
                                                onChange={handleSearchMemberText}
                                                type='text' 
                                                placeholder="Who would you like to add?" 
                                                />
                                        </div>
                                    )
                                    }
                                </div>
                            <div className='pb-4 px-4'>
                                <div className='overflow-y-scroll scrollbar-hide h-[500px] '>
                                        {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                                            <motion.div
                                                key='allMembersInOrg'
                                                initial={{ y: 10, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: -10, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                            > 

                                            {/* List of members */}
                                                <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                {usersList.map((member, index) => (
                                                    <button 
                                                        key={`${member.user_username}-usersList`} 
                                                        className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                        onClick={(e) => handleAddUser(e, member)}
                                                    >
                                                        <input 
                                                            type="checkbox" 
                                                            checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                            onChange={(e) => handleAddUser(e, member)}  
                                                            className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"

                                                        />
                                                        <div className={`relative`}>
                                                            <Avatar 
                                                            sx={{  
                                                                bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                width: 32, 
                                                                height: 32, 
                                                            // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                        }}  
                                                            src={member.user_image_url} />
                                                        </div>
                                                        <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                            <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                    <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                    <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        
                                                    </button>
                                                ))}

                                                </div>
                                            </motion.div>
                                        }
                                        {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                                            <motion.div
                                                key='filteredListSection'
                                                initial={{ y: 10, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: -10, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                            > 
                                                <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                    {filteredMemberList.map((member, index) => (
                                                    <button 
                                                        key={`${member.user_username}-${member.organization_handle}-filteredMembersList`} 
                                                        className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                        onClick={(e) => handleAddUser(e, member)}
                                                    >
                                                        <input 
                                                            type="checkbox" 
                                                            checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                            onChange={(e) => handleAddUser(e, member)}  
                                                            className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                        />
                                                        <div className={`relative`}>
                                                            <Avatar 
                                                            sx={{  
                                                                bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                width: 32, 
                                                                height: 32, 
                                                            // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                        }}  
                                                            src={member.user_image_url} />
                                                        </div>
                                                        <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                            <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                    <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'}  font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                                </div>
                                                            </div>
                                                            <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                    <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        
                                                    </button>
                                                    ))}
                                                </div>
                                            </motion.div>
                                        }

                                </div>
                            </div>
                    </motion.div>


                             <form >
                                <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                    <button
                                    type="button"
                                    className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                    onClick={closeModal}
                                    >
                                    Cancel
                                    </button>
                                    <button
                                    type="button"
                                    className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                    onClick={submitTeamInfo}
                                    disabled={loading || Object.keys(selectedMembers).length === 0}
                                    >
                                    {`Create Team`}
                                    {loading && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                        }
                                    </button>
                                </div>
                            </form>
                        </motion.div> 

                    )}
                  
                  
                  
                 

                  </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CreateNewTeamModal