import { Dialog, Transition, TransitionChild, DialogTitle, DialogPanel } from '@headlessui/react'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { PencilIcon } from "@heroicons/react/solid"
import toast from 'react-hot-toast';
import { LightTooltip } from '../../../styles/Tooltip';
import { removeMessage, updateOrInsertMessageByDate } from '../../../utils/MessageDataSorting';
import { formatFileSize } from '../../../utils/FileFormating';
import ChatInput from '../../../MyMessaging/chatComponents/ChatInput';
import EmojiPicker from '../../messagingComponents/EmojiPicker';
import MqttService from '../../../core/services/MqttService';
import { createMessagingMQTT } from '../../../core/services/MqttMessaging';
import ReactQuill from 'react-quill';

const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];


function FilePreviewModal({btnType, remoteOpen,replyMessage, setReplyMessage, handleCloseFilePreviewModal, capturedImage, selectedImageVideo,selectedImagesVideos,setSelectedImagesVideos, selectedFiles, setSelectedFiles, setIsFilePreviewModalOpen, messages, setMessages, userInfo, allEmojis, closeEverything, setCloseEverything, chatInfo, setRemoveMessageObject}) {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [isFileCompressible, setIsFileCompressible] = useState(false)
    const [compressFile, setCompressFile] = useState(false)
    const [imageSrc, setImageSrc] = useState(null);
    const [imgPreviews, setImgPreviews] = useState([]);
    const [file, setFile] = useState(null)
    const [files, setFiles] = useState(null)
    // const [messageSearchText, setMessageSearchText] = useState('')
    const [fileInputMessageText, setFileInputMessageText] = useState('')
    // const fileTextAreaRef = useRef(null);
    const quillFileRef = useRef(null);
    


    useEffect(() => {
      
        if(capturedImage ){
         
            setIsFileCompressible(true)
            setCompressFile(true)
        }else if(selectedImagesVideos && Object.keys(selectedImagesVideos).length > 0){
            setIsFileCompressible(true)
            setCompressFile(true)
            // setFile(selectedImageVideo)
            setFiles(selectedImagesVideos)
            const newPreviews = selectedImagesVideos.map(file => URL.createObjectURL(file))
            console.log(newPreviews, newPreviews)
            setImgPreviews(newPreviews);
            // const reader = new FileReader();
            // reader.onload = () => {
            //     setImageSrc(reader.result);
            // };
            // reader.readAsDataURL(selectedImageVideo);
        }else if(selectedFiles){
            setIsFileCompressible(false)
            setCompressFile(false)
            // setFile(selectedFile)
            setFiles(selectedFiles)

            // if (selectedFile.type.startsWith('image/')) {
            if (selectedFiles.every(file => imageTypes.includes(file.type))) {
                setIsFileCompressible(true)
                const newPreviews = selectedFiles.map(file => URL.createObjectURL(file))
                setImgPreviews(newPreviews);
                // const reader = new FileReader();
                // reader.onload = () => {
                //   setImageSrc(reader.result);
                // };
                // reader.readAsDataURL(selectedFile);
              } 
            // if (selectedFile.type.startsWith('image/')) {
            //   setIsFileCompressible(true)
            //   const reader = new FileReader();
            //   reader.onload = () => {
            //     setImageSrc(reader.result);
            //   };
            //   reader.readAsDataURL(selectedFile);
            // } 

        }

    },[selectedImagesVideos, capturedImage, selectedFiles])
    

    useEffect(() => {
      if(remoteOpen){
        setIsOpen(true)
      }
    },[remoteOpen])

    
    
    function closeModal() {
        setFile(null)
        setImageSrc(null)
        setImgPreviews([])
        setIsFileCompressible(false)
        setCompressFile(false)
        setIsFilePreviewModalOpen(false)
        if(handleCloseFilePreviewModal){
            handleCloseFilePreviewModal()
        }
        setIsOpen(false)
    }

    function openModal() {
      setIsOpen(true)
    }



      // console.log('selectedImagesVideos', selectedImagesVideos)
      // console.log('selectedFile', selectedFile)
      // console.log('capturedImage', capturedImage)
      
        const submitFile = async (event) => {
          

            try{
                
                let temp_file = null
                let temp_files = null
                let isCaptureImage = false
                let modifiedCapturedImage = null
                
                // Access the Quill editor instance using the ref
                const editor = quillFileRef.current.getEditor()
                
                // Get the plain text from the editor
                const plainText = editor.getText().replace(/[\n\r]/g, '')
                
                if(capturedImage ){
                    isCaptureImage = true
                    let fileName = `img_${Date.now()}.png`
                    modifiedCapturedImage =  base64ToFile(capturedImage, fileName)
                    // console.log('modifiedCapturedImage', modifiedCapturedImage)
                    temp_file = modifiedCapturedImage
                    
                    if(compressFile){
                        let compressedFile = await convertToWebP(temp_file);
                        // console.log('compressedFile', compressedFile)
                        // console.log('compressedWebp', compressedWebp)
                        compressedFile.name = fileName + '.webp'
                        temp_file = compressedFile
                        // await handleUpload(compressedFile, compressFile, replyMessage, userInfo, messageSearchText)
                        await handleUpload(compressedFile, compressFile, replyMessage, userInfo, fileInputMessageText, plainText)
                    }else{
                      // await handleUpload(modifiedCapturedImage, compressFile, replyMessage, userInfo, messageSearchText)
                      await handleUpload(modifiedCapturedImage, compressFile, replyMessage, userInfo, fileInputMessageText, plainText)
                    }

                }else if(selectedImagesVideos){
                  let lastIndex = Object.keys(selectedImagesVideos).length - 1
                    if(compressFile){
                        let temp_files = []
                        selectedImagesVideos.map( async (currentImg, index) => {
                          let compressedFile = await convertToWebP(currentImg);
                          compressedFile.name = currentImg.name + '.webp'
                          temp_files.push(compressedFile)
                          let text  = ''
                          let reply = []
                          if(lastIndex == index){
                            text = fileInputMessageText
                            // text = messageSearchText
                          }
                          if(index === 0){
                            reply = replyMessage
                          }
                          await handleUpload(compressedFile, compressFile, reply, userInfo, text, plainText)
                          
                        })
                    }else{
                      // temp_files = selectedImagesVideos
                      selectedImagesVideos.map( async (currentFile, index) => {
                        let text  = ''
                        let reply = []
                        if(lastIndex == index){
                          text = fileInputMessageText
                          // text = messageSearchText
                        }
                        if(index === 0){
                          reply = replyMessage
                        }
                        await handleUpload(currentFile, compressFile, reply, userInfo, text, plainText)
                      })
                    }
                }else if(selectedFiles){
                  let lastIndex = Object.keys(selectedFiles).length - 1
                  // temp_files = selectedFiles
                  selectedFiles.map( async (currentFile, index) => {
                    let text  = ''
                    let reply = []
                    if(lastIndex == index){
                      text = fileInputMessageText
                      // text = messageSearchText
                    }
                    if(index === 0){
                      reply = replyMessage
                    }
                    
                    await handleUpload(currentFile, compressFile, reply, userInfo, text, plainText)

                  })
                }
                closeModal()

            }catch (error) {
                console.error('Error uploading file:', error);
                toast.error('Failed to upload file.');
            }

            
        }

    const base64ToFile = (base64String, filename) => {
        // Extract the file type from the base64 string
        // const mimeType = base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).,./)[1];
        const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
        
        // Remove the data URL prefix
        const base64Data = base64String.split(',')[1];
        
        // Decode base64 string
        const byteCharacters = atob(base64Data);
        
        // Convert to byte array
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        
        // Create a Blob object
        const blob = new Blob([byteArray], { type: mimeType });
        
        // Create a File object from the Blob
        return new File([blob], filename, { type: mimeType });
    };

    // old function to convert iamges to jpeg
    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
    
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
    
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
    
              // Set canvas dimensions to desired size
              const maxWidth = 800; // Max width of the compressed image
              const maxHeight = 800; // Max height of the compressed image
              let width = img.width;
              let height = img.height;
    
              // Maintain aspect ratio
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }
    
              canvas.width = width;
              canvas.height = height;
    
              // Draw the image on the canvas
              ctx.drawImage(img, 0, 0, width, height);
    
              // Get the compressed image data
              canvas.toBlob(
                (blob) => {
                  resolve(blob);
                },
                'image/jpeg', // Change format to JPEG
                0.8 // Quality level (0.1 to 1.0)
              );
            };
    
            img.onerror = (error) => {
              reject(error);
            };
          };
    
          reader.onerror = (error) => {
            reject(error);
          };
        });
      };

      const convertToWebP = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              let width = img.width;
              let height = img.height;
    
              // Calculate new dimensions
              if (width > height) {
                if (width > 1600) {
                  height *= 1600 / width;
                  width = 1600;
                }
              } else {
                if (height > 1600) {
                  width *= 1600 / height;
                  height = 1600;
                }
              }
    
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
              
              canvas.toBlob((blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('WebP conversion failed'));
                }
              }, 'image/webp');
            };
            img.onerror = () => reject(new Error('Image loading failed'));
            img.src = event.target.result;
          };
          reader.onerror = () => reject(new Error('File reading failed'));
          // console.log('file', file)
          reader.readAsDataURL(file);
        });
      };


    const handleInsertEmoji = (emoji) => {
        // setMessageSearchText(messageSearchText + emoji); 
        // fileTextAreaRef.current.focus();


        const quill = quillFileRef.current.getEditor();
        const range = quill.getSelection(true);
        console.log('range', range)
        if (range) {
            quill.insertText(range.index, emoji);
        } else {
            quill.insertText(quill.getLength() - 1, emoji);
        }


        quillFileRef.current.getEditor().focus({ preventScroll: true });

    }; 


    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter' && e.shiftKey) {
    //         e.preventDefault(); // Prevent form submission
    //         setMessageSearchText(messageSearchText + '\n'); // Add a new line character

    //     }else if(e.keyCode === 13) { // Enter key pressed    
    //         e.preventDefault(); // Prevent default submission for Enter
    //         submitFile()
    //         // addMessage(e, 'userEnter');
    //     }
    // };

    
  
  const removeFileHandle = (indexToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));

  }

  const getGridClassName = () => {
    const count = imgPreviews.length;
    if (count === 1) return 'grid-cols-1';
    if (count === 2) return 'grid-cols-2';
    if (count === 3) return 'grid-cols-3';
    return 'grid-cols-2';
  };
  

  const handleUpload = async (current_file, isFileCompressed, reply_msg, userInfo, msg_text, plainText) => {
    
    return new Promise((resolve, reject) => {
      try {

        let url = ''
        if(current_file){
            url = `?file_name=${current_file.name}&file_size=${current_file.size}&file_type=${current_file.type}&file_is_compressed=${isFileCompressed}`
        }
        let reply_uuid =  ''
        if(reply_msg && reply_msg.message_uuid){
            reply_uuid = reply_msg.message_uuid
            setReplyMessage([])
        }


        
        getData(apiLink + '/v1/organizations/' + selectedOrganization + `/file_upload_request` + url)
        .then(async response => {
            if(response.ok ){
            const data = await response.json();

              if(data){

                  let url = data.data.signed_url
                  let file_uuid = data.data.file_uuid

                      // Create a new XMLHttpRequest to upload the file
                      // const xhr = new XMLHttpRequest();
                      // xhr.open('PUT', url, true);
                      // xhr.setRequestHeader('Content-Type', file.type);
                      let currentTime = new Date().toISOString();
                      let message_uuid = `msg_${userInfo.user_username}_temp_${Date.now()}_${parseInt(Math.random()*100000)}`

                      

                      let constructedMessage = {
                          "message_uuid": message_uuid,
                          "message_created_at": currentTime,
                          "message_text": plainText,
                          'message_text_html': msg_text,
                          "message_file_uuid": file_uuid,
                          'user_first_name':userInfo.user_first_name,
                          'user_username':userInfo.user_username,
                          'isFileUploading': true,
                          'file_name':current_file.name,
                          'file_size':current_file.size,
                          'reply_from_message_uuid':reply_uuid,
                          percent: 0,
                          xhr: new XMLHttpRequest()
                      }
                      constructedMessage.xhr.open('PUT', url, true);
                      constructedMessage.xhr.setRequestHeader('Content-Type', current_file.type);

                      const updatedMessages = updateOrInsertMessageByDate(messages, constructedMessage)
                      console.log('constructedMessage',constructedMessage.message_uuid)
                      setMessages({...updatedMessages})
              
                      constructedMessage.xhr.upload.onprogress = (event) => {
                      if (event.lengthComputable) {
                          const percentComplete = (event.loaded / event.total) * 100;
                          console.log('percentComplete',constructedMessage.message_uuid, percentComplete)
                          
                          
                            setMessages((prevMessages) => ({
                              ...prevMessages,
                              [message_uuid]: { ...prevMessages[message_uuid], progress: percentComplete }
                            }));
                          // setUploadProgress(percentComplete);
                      }
                      };
                      // closeModal()
              
                      constructedMessage.xhr.onload = () => {
                      if (constructedMessage.xhr.status === 200) {

                          // POST MQTT MESSAGE
                          if (MqttService.isConnected) {
                              let msg = {
                                  from_username: userInfo.user_username,
                                  to_chatroom_uuid: chatInfo.chatroom_uuid,
                                  action: 'post_message',
                                  message_text: constructedMessage.message_text,
                                  message_text_html: constructedMessage.message_text_html,
                                  message_file_uuid: constructedMessage.message_file_uuid,
                                  reply_from_message_uuid: constructedMessage.reply_from_message_uuid,
                              }
                              
                              msg = createMessagingMQTT(msg);
                              console.log('current_file',current_file.name,msg.payload.message_uuid)
                              if(msg.topic && msg.payload)
                              {
                                  MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
                              }
                              // setMessageSearchText('')
                              setFileInputMessageText('')
                              
                          }else{
                              toast.error('Waiting for Network')
                          }

                          setRemoveMessageObject(constructedMessage)
                          // const updatedMessages = removeMessage(messages, constructedMessage)
                          // console.log('updatedMessages', updatedMessages)
                          // setMessages({...updatedMessages})

                          setTimeout(() => {
                            resolve()
                          },2000)
                          // toast.success('File uploaded successfully!');
                          // setUploadProgress(0); // Reset progress after upload
                          // setFile(null); // Reset file input after upload
                          // closeModal()

                        }else{
                            toast.error('Failed to upload file.');
                            reject('Failed to upload file.');
                        }
                      };
              
                      constructedMessage.xhr.onerror = () => {
                          toast.error('An error occurred while uploading the file.');
                          reject('An error occurred while uploading the file.');
                      };
              
                      constructedMessage.xhr.send(current_file);

                      // setTimeout(() =>{
                      //     constructedMessage.xhr.abort()
                      //     toast.error('Message Aborted')
                      // },500)

              }else{
                reject('error');
              }
            }else{
              reject('error');
            }
        })

        
      } catch (error) {
        console.error('Error uploading files:', error);
        reject(error);
      }
    })
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const modules = useMemo(
    () => ({
    toolbar: false, // Remove the toolbar by setting it to false
    keyboard: {
        bindings: {
            // Custom binding for Enter key to send the message
            sendOnEnter: {
                key: 'Enter',
                handler: (range, context) => {
                if (!context.shiftKey) {
                    return false; // Prevent the default behavior
                }
                return true; // Allow default behavior (new line) if Shift is pressed
                },
            },
        },
    },
  }),[]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        submitFile()
    }
  }, [fileInputMessageText]);



  useEffect(() => {
    console.log('isOpen', isOpen)
    console.log('quillFileRef', quillFileRef)
    if (isOpen && quillFileRef.current && quillFileRef.current.focus) {
      quillFileRef.current.focus();
    }
  }, [isOpen, quillFileRef]);



  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  
            whileHover={{scale: 1.05} } 
            whileTap={{scale:0.95 }}   
            className='btnMain disabled:opacity-50' 
            onClick={openModal}
          >
            <PencilIcon className='btnMainIcon' />
            <span className='btnMainText pl-0.5'>File Preview</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button 
        whileHover={{scale: 1.05} } 
        whileTap={{scale:0.95 }}  
          className='flex w-20 text-sm items-baseline ml-2 disabled:opacity-50' onClick={openModal} 
        >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
      }
      {btnType ==='inlineIconBtn' &&
        <button 
          className={`${isDark?'text-blue-400 hover:text-blue-200 disabled:text-gray-500':'text-blue-500 hover:text-blue-900 disabled:text-gray-300'} flex ml-auto `} 
          onClick={openModal}
        >
          <PencilIcon className={`w-5 h-5`}/>
        </button>
      }
      {btnType ==='hideBtn' &&
        <div className='w-0 h-0'>{''}</div>
      }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-[500px] transform rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  {/* <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Members
                  </DialogTitle> */}
                  <AnimatePresence mode='wait'>
                    <motion.div
                            key='fileContainerDiv'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='relative px-4 pt-2'
                        >
                            <div className='flex'>
                                {isFileCompressible &&
                                <LightTooltip title='Send in a quick way'>
                                    <button className={` ${compressFile ? 'text-blue-500':'text-gray-500'} mr-2`} onClick={() => setCompressFile(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-7">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                        </svg>
                                    </button>
                                </LightTooltip>
                                }
                                <LightTooltip title='Send without compression' >
                                    <button className={`${!compressFile ? 'text-blue-500':'text-gray-500'} `} onClick={() => setCompressFile(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>
                                    </button>
                                </LightTooltip>
                                <motion.button 
                                    whileHover={{scale: 1.05} } 
                                    whileTap={{scale:0.95 }}  
                                    className={`${isDark ? 'text-blue-500':'text-blue-500'} ml-auto`} onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>

                                </motion.button>
                            </div>
                            <div>
                                {capturedImage && (
                                <div>
                                    <img src={capturedImage} alt="Captured" className='object-fill ' style={{ width: '100%', maxWidth: '500px' }} />
                                </div>
                                )}
                            </div>
                            <div>
                                {/* {imageSrc && !compressFile && file && ( */}
                                {(imgPreviews && Object.keys(imgPreviews).length > 0 && !compressFile && files)? (
                                  <div className={`flex flex-col space-y-1 max-h-[500px] overflow-y-scroll scrollbar-hide`}>
                                    {files.map((file, index) => (
                                      <motion.div
                                          key={`uncompressedSelectedImageContainer-${index + 1}`}
                                          initial={{ y: 10, opacity: 0 }}
                                          animate={{ y: 0, opacity: 1 }}
                                          exit={{ y: -10, opacity: 0 }}
                                          transition={{ duration: 0.2 }}
                                      >
                                          <div className='flex items-center px-4 pt-2 group'>
                                              <div className={``}>
                                                  <img src={imgPreviews[index]} alt={`Preview ${index + 1}`} className='object-fill rounded-md' style={{ width: '100%', maxWidth: '75px' }} />
                                              </div>
                                              <div className='pl-2 flex flex-col'>
                                                  <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                                      {file.name? file.name : 'Unknown'}
                                                  </h2>
                                                  <span className={`text-sm ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                                      {formatFileSize(file.size)}
                                                  </span>
                                              </div>
                                              <div className='ml-auto'>
                                                  <motion.button  
                                                      whileHover={{scale: 1.05} } 
                                                      whileTap={{scale:0.95 }}  
                                                      className={`${isDark ? 'text-blue-500':'text-blue-600'} opacity-0 ease-in-out duration-300 group-hover:opacity-100`} 
                                                      onClick={closeModal}
                                                  >
                                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                      </svg>
                                                  </motion.button>
                                              </div>

                                          </div>
                                      </motion.div>
                                    ))}
                                    {/* <motion.div
                                        key='uncompressedSelectedImageContainer'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <div className='flex items-center px-4 pt-2 group'>
                                            <div className={``}>
                                                <img src={imageSrc} alt="Captured" className='object-fill rounded-md' style={{ width: '100%', maxWidth: '75px' }} />
                                            </div>
                                            <div className='pl-2 flex flex-col'>
                                                <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                                    {file.name? file.name : 'Unknown'}
                                                </h2>
                                                <span className={`text-sm ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                                    {formatFileSize(file.size)}
                                                </span>
                                            </div>
                                            <div className='ml-auto'>
                                                <motion.button  
                                                    whileHover={{scale: 1.05} } 
                                                    whileTap={{scale:0.95 }}  
                                                    className={`${isDark ? 'text-blue-500':'text-blue-600'} opacity-0 ease-in-out duration-300 group-hover:opacity-100`} 
                                                    onClick={closeModal}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </motion.button>
                                            </div>

                                        </div>
                                    </motion.div> */}
                                  </div>
                                ): null}
                                {imgPreviews && Object.keys(imgPreviews).length > 0 && compressFile && (
                                  <div className={`grid ${getGridClassName()}  max-h-[500px] overflow-y-scroll scrollbar-hide`}>
                                    {files.map((file, index) => (
                                    <motion.div
                                        key={`compressedSelectedImageContainer-${index}`}
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className='m-2 '
                                    >
                                        <img src={imgPreviews[index]} alt="Captured" className='object-fill rounded-lg' style={{ width: '100%', maxWidth: '500px'}} />
                                    </motion.div>
                                    ))}
                                  </div>
                                )}
                            </div>
                            {/* {(!imageSrc && selectedFiles) ? ( */}
                            {(imgPreviews.length === 0  && selectedFiles) ? (
                              <div className='flex flex-col space-y-1 max-h-[500px] overflow-y-scroll scrollbar-hide'>
                                {selectedFiles.map((file, index) => (
                                  <div key={file.name} className='flex items-center px-4 pt-4 group'>
                                      <div className={`w-fit p-2 rounded-full ${isDark ? 'bg-sky-500':'bg-sky-600'} text-white`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                              <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                              <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                          </svg>
                                      </div>
                                      <div className='pl-2 flex flex-col'>
                                          <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                              {file.name? file.name : 'Unknown'}
                                          </h2>
                                          <span className={`text-sm ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                              {formatFileSize(file.size)}
                                          </span>
                                      </div>
                                      <div className='ml-auto'>
                                          <button className={`${isDark ? 'text-blue-500':'text-blue-600'} opacity-0 ease-in-out duration-300 group-hover:opacity-100`} onClick={() => removeFileHandle(index)}>
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                              </svg>
                                          </button>
                                      </div>
  
                                  </div>
                                ))}
                              </div>
                                // <div className='flex items-center px-4 pt-4 group'>
                                //     <div className={`w-fit p-2 rounded-full ${isDark ? 'bg-sky-500':'bg-sky-600'} text-white`}>
                                //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                //             <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                //             <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                //         </svg>
                                //     </div>
                                //     <div className='pl-2 flex flex-col'>
                                //         <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                //             {selectedFile.name? selectedFile.name : 'Unknown'}
                                //         </h2>
                                //         <span className={`text-sm ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                //             {formatFileSize(selectedFile.size)}
                                //         </span>
                                //     </div>
                                //     <div className='ml-auto'>
                                //         <button className={`${isDark ? 'text-blue-500':'text-blue-600'} opacity-0 ease-in-out duration-300 group-hover:opacity-100`} onClick={closeModal}>
                                //             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                //                 <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                //             </svg>
                                //         </button>
                                //     </div>

                                // </div>
                            ):(
                              <div />
                            )
                            }
                    </motion.div>
                  </AnimatePresence>
                  <div className={`flex py-1 border-t-[0.01em] ${isDark ? 'border-slate-600':''} pl-2 pr-4 py-2 mt-4 z-0`}>
                        <div className='flex flex-1 min-w-0 items-center pt-1'>
                          <ReactQuill
                            ref={quillFileRef}
                            value={fileInputMessageText}
                            onChange={(e)=> setFileInputMessageText(e)} 
                            onKeyDown={handleKeyDown}
                            // modules={{ toolbar: false }} // Disable toolbar
                            modules={modules} // Apply custom keyboard bindings
                            // formats={[]} // Disable all formats if needed
                            placeholder="Type a message..."
                            spellCheck="true"
                            className={`custom-quill w-full ${isDark ? 'text-gray-300 dark:placeholder:text-gray-500':'text-gray-600 placeholder:text-gray-400'}  `}
                            formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote',
                                'list',
                                'bullet',
                                'link',
                                'image',
                              ]}
                          />
                            {/* <textarea
                                rows="1"
                                ref={fileTextAreaRef}
                                style={{height: '28px'}}
                                className={`w-full font-light text-base resize-none max-h-52 overflow-y-scroll scrollbar-hide border-0 focus:ring-[0px]
                                    ${isDark ? 'text-gray-300 placeholder:text-gray-500 bg-slate-800':'text-gray-600 placeholder:text-gray-400'} 
                                    py-0 disabled:cursor-not-allowed`}
                                value={messageSearchText} 
                                onChange={(e)=> setMessageSearchText(e.target.value)} 
                                onKeyDown={handleKeyDown}
                                type="text" 
                                placeholder="Add a caption"
                                spellCheck="true"
                            /> */}
                        </div>
                        <div className='flex items-center mr-3 z-[9999]'>
                            <EmojiPicker onEmojiSelect={handleInsertEmoji} allEmojis={allEmojis} closeEverything={closeEverything} setCloseEverything={setCloseEverything} />
                        </div>
                        <div className='flex items-center'>
                            <motion.div
                                key='submitButton'
                                className='flex items-center'
                            >
                                <button 
                                    disabled={loading }
                                    onClick={submitFile}
                                    className='relative text-blue-500 disabled:text-blue-500/70'
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                    </svg>
                                    {loading && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                        }
                                </button>
                            </motion.div>
                        </div>
                    </div>

                {/* <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        
                        disabled={loading }
                        onClick={submitFile}
                      >
                        Send
                        {loading && 
                          <div >
                              <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                              </svg>
                          </div>
                          }
                      </button>
                    </div>
                  </form> */}
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default FilePreviewModal