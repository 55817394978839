import React, { useEffect, useState } from 'react'
import MobileRightDrawer from '../components/MobileRightDrawer'
import { useLocation, useSearchParams } from 'react-router-dom'
import WorkOrderViewSelector from '../components/filterButtons/WorkOrderFilters/WorkOrderViewSelector'
import { motion, AnimatePresence } from 'framer-motion'
import WorkOrderTableView from './tableViewComponents/WorkOrderTableView'

const workOrders = [
    {
      id: "WO-001",
      title: "Routine Maintenance",
      status: "In Progress",
      priority: "High",
      assignedTo: "John Doe",
      asset: "HVAC System",
      createdBy: "Alice Smith",
      createdOn: "2023-10-12",
      updatedOn: "2023-10-14",
      dueDate: "2023-10-20",
      recurrence: "Monthly"
    },
    {
      id: "WO-002",
      title: "Repair Leaking Pipe",
      status: "Pending",
      priority: "Medium",
      assignedTo: "Mary Johnson",
      asset: "Plumbing System",
      createdBy: "Alice Smith",
      createdOn: "2023-10-10",
      updatedOn: "2023-10-10",
      dueDate: "2023-10-15",
      recurrence: "None"
    },
    {
      id: "WO-003",
      title: "Replace Air Filter",
      status: "Completed",
      priority: "Low",
      assignedTo: "James Brown",
      asset: "Air Handling Unit",
      createdBy: "Bob White",
      createdOn: "2023-10-01",
      updatedOn: "2023-10-05",
      dueDate: "2023-10-05",
      recurrence: "Quarterly"
    },
    {
      id: "WO-004",
      title: "Inspect Safety Equipment",
      status: "In Progress",
      priority: "High",
      assignedTo: "Emily Clark",
      asset: "Safety Equipment",
      createdBy: "Alice Smith",
      createdOn: "2023-09-28",
      updatedOn: "2023-10-12",
      dueDate: "2023-10-18",
      recurrence: "Annually"
    },
    {
      id: "WO-005",
      title: "Test Backup Generator",
      status: "Pending",
      priority: "High",
      assignedTo: "Tom Walker",
      asset: "Backup Generator",
      createdBy: "Alice Smith",
      createdOn: "2023-10-02",
      updatedOn: "2023-10-02",
      dueDate: "2023-10-22",
      recurrence: "Semi-Annually"
    },
    {
      id: "WO-006",
      title: "Calibrate Sensors",
      status: "Completed",
      priority: "Medium",
      assignedTo: "Laura Martinez",
      asset: "Sensor Suite",
      createdBy: "Bob White",
      createdOn: "2023-09-20",
      updatedOn: "2023-09-25",
      dueDate: "2023-09-25",
      recurrence: "Monthly"
    },
    {
      id: "WO-007",
      title: "Lubricate Conveyor Belt",
      status: "In Progress",
      priority: "Low",
      assignedTo: "Anna Lee",
      asset: "Conveyor System",
      createdBy: "Alice Smith",
      createdOn: "2023-10-08",
      updatedOn: "2023-10-13",
      dueDate: "2023-10-19",
      recurrence: "Weekly"
    },
    {
      id: "WO-008",
      title: "Check Fire Alarm System",
      status: "Pending",
      priority: "High",
      assignedTo: "Chris Evans",
      asset: "Fire Alarm System",
      createdBy: "Bob White",
      createdOn: "2023-09-30",
      updatedOn: "2023-10-10",
      dueDate: "2023-10-20",
      recurrence: "Annually"
    },
    {
      id: "WO-009",
      title: "Replace Broken Window",
      status: "In Progress",
      priority: "Medium",
      assignedTo: "Nancy Lopez",
      asset: "Building Structure",
      createdBy: "Alice Smith",
      createdOn: "2023-10-11",
      updatedOn: "2023-10-15",
      dueDate: "2023-10-25",
      recurrence: "None"
    },
    {
      id: "WO-010",
      title: "Clean Air Ducts",
      status: "Completed",
      priority: "Low",
      assignedTo: "Steve Kim",
      asset: "Ventilation System",
      createdBy: "Alice Smith",
      createdOn: "2023-09-15",
      updatedOn: "2023-09-18",
      dueDate: "2023-09-18",
      recurrence: "Annually"
    },
  ];

function WorkOrdersPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const location = useLocation();
    const [workOrdersList, setWorkOrdersList] = useState(workOrders)

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])



  return (
    <div className='mt-2 p-2 h-screen scrollbar-hide'>
        <div className={`${isMobile ? 'flex':'flex md:hidden'} justify-end mr-4`}>
            <MobileRightDrawer >
                <div className='flex flex-col space-y-3'>
                {/* Filters for workorders */} 
                </div>

            </MobileRightDrawer>
        </div>
        {/* Filter section */}
        <div className={`${isMobile && 'hidden'} flex flex-col xl:flex-row xl:items-center mb-4 justify-end `}>
            {/* Filters for workorders */} 
            <WorkOrderViewSelector />
        </div>
        <div className='mt-2'>

            {showView('table_view', workOrdersList)}

        </div>
    </div>
  )
}

export default WorkOrdersPage


const showView = (view, workOrdersList ) =>{
    const allLinks = {
        todo_view: { content: <div>To do </div>},
        table_view: { content: <WorkOrderTableView workOrdersList={workOrdersList}  /> },
        calendar_view: { content: <div>Calendar </div>},
        workload_view: { content: <div>Workload </div>},
    };
    

    return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={view}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='pb-10'
        >
            {allLinks[view].content} 
        </motion.div>
      </AnimatePresence>
    )  
}