

export function formatDateToAmPm(dateString) {
    try {
        if(dateString){
            // Parse the date string considering UTC timezone
            const dateObj = new Date(dateString);
            // console.log('dateObj', dateObj)
      
          // Format the date in 12-hour AM/PM format with leading zeros for hours and minutes
          const formattedTime = dateObj.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true, // Use 12-hour clock
          });
      
          return formattedTime;
        }
    } catch (error) {
      console.error('Error formatting date:', error);
      // Optionally return an empty string or a default value for invalid dates
      return ''; // Or a default value like 'Invalid Date'
    }
  }


  export function formatDateToDayMonthYear(dateString) {
    try {
        if(dateString){
        // Parse the date string considering UTC timezone
        const dateObj = new Date(dateString);

        // Extract day, month (0-indexed), and year
        const day = dateObj.getDate();
        const month = dateObj.getMonth();
        const year = dateObj.getFullYear();
    
        // Use locale-aware formatting for full month name (options for different locales)
        const fullMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
    
        // Format the date as "Numeric Day Full Month Numeric Year" (adjust as needed)
        return `${day} ${fullMonth} ${year}`;

        }
    } catch (error) {
        console.log('Error parsing date:', error);
        // Optionally return an empty string or a default value for invalid dates
        return ''; // Or a default value like 'Invalid Date'
    }
  }

  export function formatTimeOrDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    // Function to pad single digit numbers with leading zero
    const pad = (num) => num.toString().padStart(2, '0');
  
    // Check if the date is today
    if (date.toDateString() === now.toDateString()) {
      let hours = date.getHours();
      const minutes = pad(date.getMinutes());
      const ampm = hours >= 12 ? 'PM' : 'AM';
      
      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      
      return `${pad(hours)}:${minutes} ${ampm}`;
    } else {
      // If it's not today, return MM/DD/YY
      const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
      const day = pad(date.getDate());
      const year = date.getFullYear().toString().slice(-2);
      
      return `${month}/${day}/${year}`;
    }
  }


  export function formatRelativeTime(dateString) {
        if(dateString){
            const thenDate = new Date(dateString);
            const now = new Date();
            
            const diffInDays = Math.floor((now - thenDate) / (1000 * 60 * 60 * 24));
          
            if (diffInDays === 0) {
              return thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Output time for same day
            } else if (diffInDays === 1) {
              return 'Yesterday';
            } else if (diffInDays >= 2 && diffInDays <= 4) {
              return thenDate.toLocaleDateString([], { weekday: 'long' }); // Output day of the week (2-4 days ago)
            } else {
              return thenDate.toLocaleDateString([],  { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/'); // Month/Day/Year (YYYY-MM-DD)
            }
        }
    }

  export function formatDateTime(dateString) {
    try {
      if(dateString){
          // Parse the date string considering UTC timezone
          const dateObj = new Date(dateString);
          // console.log('dateObj', dateObj)
    
        // Format the date in 12-hour AM/PM format with leading zeros for hours and minutes
        const formattedTime = dateObj.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // Use 12-hour clock
        });

        const thenDate = new Date(dateString);
        const now = new Date();
        
        const diffInDays = Math.floor((now - thenDate) / (1000 * 60 * 60 * 24));
      
        if (diffInDays === 0) {
          return thenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' at ' + formattedTime; // Output time for same day
        } else if (diffInDays === 1) {
          return 'Yesterday at' +  formattedTime;
        } else if (diffInDays >= 2 && diffInDays <= 4) {
          return thenDate.toLocaleDateString([], { weekday: 'long' }) + ' at ' + formattedTime; // Output day of the week (2-4 days ago)
        } else {
          return thenDate.toLocaleDateString([],  { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '/') + ' at ' + formattedTime; // Month/Day/Year (YYYY-MM-DD)
        }
    
      }
  } catch (error) {
    console.error('Error formatting date:', error);
    // Optionally return an empty string or a default value for invalid dates
    return ''; // Or a default value like 'Invalid Date'
  }

  }