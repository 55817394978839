
import React, { useState } from "react";
import { motion } from "framer-motion";

const views = [
  { 
    name: "To Do View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm120-80v-560H200v560h120Zm80 0h360v-560H400v560Zm-80 0H200h120Z"/>
        </svg>, 
    id: "todo_view" },
  { 
    name: "Table View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path d="M320-600q-17 0-28.5-11.5T280-640q0-17 11.5-28.5T320-680h480q17 0 28.5 11.5T840-640q0 17-11.5 28.5T800-600H320Zm0 160q-17 0-28.5-11.5T280-480q0-17 11.5-28.5T320-520h480q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H320Zm0 160q-17 0-28.5-11.5T280-320q0-17 11.5-28.5T320-360h480q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H320ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z"/>
        </svg>, 
    id: "tableo_view" },
  { 
    name: "Calendar View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/>
        </svg>, 
    id: "calendaro_view" },
  { 
    name: "Workload View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M418-340q24 24 62 23.5t56-27.5l169-253q9-14-2.5-25.5T677-625L424-456q-27 18-28.5 55t22.5 61Zm62-460q36 0 71 6t68 19q16 6 34 22.5t10 31.5q-8 15-36 20t-45-1q-25-9-50.5-13.5T480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-26-4.5-51T782-504q-6-17-2-33t18-27q13-10 28.5-6t21.5 18q15 35 23 71.5t9 74.5q1 57-13 109t-41 99q-11 18-30 28t-40 10H204q-21 0-40-10t-30-28q-26-45-40-95.5T80-400q0-83 31.5-155.5t86-127Q252-737 325-768.5T480-800Zm7 313Z"/>
        </svg>, 
    id: "workloado_view" },
];

const WorkOrderViewSelector = () => {
  const [selectedView, setSelectedView] = useState(views[0].name);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (view) => {
    setSelectedView(view.name);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-[190px]">
      {/* Dropdown Button */}
      <motion.button  
        whileHover={{ scale: 1.03 }} 
        whileTap={{ scale: 0.98 }}
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex w-full justify-between items-center px-4 py-2 text-gray-700 dark:text-gray-200 bg-slate-100 dark:bg-slate-600 dark:hover:bg-slate-600/80 text-sm font-medium rounded-md border-slate-50 dark:border-slate-400"
        // className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
      >
        <span className="flex items-center">
          {views.find((view) => view.name === selectedView).icon}
          <span className="ml-2">{selectedView}</span>
        </span>
        <svg
          className={`w-5 h-5 ml-2 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06-.02L10 10.94l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.08 0L5.23 8.27a.75.75 0 01-.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </motion.button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-2 w-full bg-white dark:bg-slate-600 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10 p-1">
          {views.map((view) => (
            <button
              key={view.id}
              onClick={() => handleSelect(view)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md focus:outline-none"
            >
              <span className="mr-2">{view.icon}</span>
              {view.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkOrderViewSelector;
