import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilState, useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import { sendUrlLinkModalState,} from '../../../atoms/modalStates'
import GeneralFormInput from '../../forms/GeneralFormInput';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import toast from 'react-hot-toast';
import { getData, postData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import ScreenRow from './ScreenRow';


function SendUrlLinkModal({btnType, remoteOpenGatewayModal}) {
  const isDark = useRecoilValue(darkModeState)
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [isOpen, setIsOpen] = useState(false)
  const [screenList, setScreenList] = useState([])
  const [isSendUrlLinkModalOpen, setIsSendUrlLinkModalOpen] = useRecoilState(sendUrlLinkModalState)
  const [loading, setLoading] = useState(false)
  const [selectedScreen, setSelectedScreen] = useState([])
  const [loadingAddScreen, setLoadingAddScreen] = useState(false)
  const [screenCodeSuccessful, setScreenCodeSuccessful] = useState(false)
  const [loadingScreenName, setLoadingScreenName] = useState(false)
  const [urlLink, setUrlLink] = useState({
    link: window.location.href,
  })
  const [screenCode, setScreenCode] = useState({
    addScreenCode: '',
    screenName:''
  })
  const [isAddingScreen, setIsAddingScreen] = useState(false)
  const [newScreenUuid, setNewScreenUuid] = useState('')

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    setIsSendUrlLinkModalOpen(false)
  }

  useEffect(() => {
    if(remoteOpenGatewayModal){
      setIsOpen(true)
    }
  },[remoteOpenGatewayModal])

  useEffect(() => {
    refreshScreenList()
  },[])

  const refreshScreenList = () => {
    // Get Screen List
    if( selectedOrganization){
      // Get list of machines
      getData(apiLink + '/v1/organizations/' + selectedOrganization + '/tvs')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data.data){
            setScreenList(data.data)
          }
        }
      });

  }
  }
  

  const handleGeneralGatewayFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...urlLink,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setUrlLink(updatedForm);

  }

  const handleScreenCodeChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...urlLink,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setScreenCode(updatedForm);

  }

  const sendScreenUrl = () => {
    try {
      if(!loading && selectedOrganization){
        setLoading(true)
        putData(apiLink + '/v1/tvs/' + selectedScreen.tv_uuid + '/url', 
        {'url': urlLink.link})
          .then(async response => {
            if(response.ok){
              // const data = await response.json()
              setLoading(false)
              toast.success('Link has been sent')
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })

      }
      
    } catch (error) {
      console.log(error.message)
    }
  }


  const handleSubmitNewTVCode = () => {
    try {
      if(!loadingAddScreen && selectedOrganization){
        setLoadingAddScreen(true)
        console.log('Submit new code', screenCode.addScreenCode)
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/tvs', 
        {'key': screenCode.addScreenCode})
          .then(async response => {
            if(response.ok){
              const data = await response.json();
              setNewScreenUuid(data.data.tv_uuid)
              setLoadingAddScreen(false)
              setScreenCodeSuccessful(true)
              toast.success('Screen has been added')
              refreshScreenList()
            }
            else{
              try{
                const data = await response.json();
                setLoadingAddScreen(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoadingAddScreen(false)
              }
            }
          })

      }
      
    } catch (error) {
      console.log(error.message)
    }

  }
  const submitNewName = () => {
    try {
      if(!loadingScreenName && selectedOrganization){
        setLoadingScreenName(true)
        putData(apiLink + '/v1/tvs/' + newScreenUuid, 
        {'tv_name': screenCode.screenName})
          .then(async response => {
            if(response.ok){
              // const data = await response.json();
              setLoadingScreenName(false)
              setScreenCodeSuccessful(false)
              setIsAddingScreen(false)
              toast.success('Screen name added')
              setScreenCode({
                addScreenCode: '',
                screenName:''
              })
              refreshScreenList()
            }
            else{
              try{
                const data = await response.json();
                setLoadingScreenName(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoadingScreenName(false)
              }
            }
          })

      }
      
    } catch (error) {
      console.log(error.message)
    }

  }

  const handleSelectedScreen = (screen) => {
    setSelectedScreen(screen)
  }


  console.log(isDark, isDark)

  return (
    <>
      <div className="flex items-center justify-center">
        {btnType === 'standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Send link</span>
          </motion.button>
          }
        {btnType === 'tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Send</span>
        </motion.button>
          }
        {btnType === 'hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px]  ${isDark ? ' text-gray-200 border-gray-700 ':' text-gray-700 border-gray-200 '}`}
                  >
                    Send URL Link
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                  <motion.div
                        key='selectScreenPage'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      > 
                        <div className='px-4'>
                          <div className='mb-4 mt-4'>
                              <div className='flex flex-col'>
                                  {/* <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Send URL Link</span> */}
                                  <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                    This will be the url link that you will send to the selected screen
                                  </span>
                              </div>

                              {/* Sensor QR code */}
                              <div className='pt-4'>
                              <GeneralFormInput  label='Url link' type='text' placeholder='' defaultValue={urlLink.link} name='link' onChange={handleGeneralGatewayFormChange} />
                              </div>
                          </div>
                          <p className={` text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}>
                              Select Screen
                          </p>
                          {screenList.map((screen, index) => (
                            <div key={screen.tv_uuid || index}  >
                              <ScreenRow screen={screen} selectedScreen={selectedScreen} handleSelectedScreen={handleSelectedScreen} refreshScreenList={refreshScreenList} />
                            </div>
                          ))}
                          <div className='mt-2'>
                            {isAddingScreen && !screenCodeSuccessful &&
                              <motion.div
                                key='addScreenCodeDiv'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className='flex flex-col mt-8'
                                >
                                  <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                    Enter the code displayed on your screen
                                  </span>
                                  <div className='pt-2'>
                                    <GeneralFormInput  label='Screen code' type='number' placeholder='Enter code'  name='addScreenCode' onChange={handleScreenCodeChange} />
                                  </div>  
                                  <div className={`flex items-center mt-3`}>
                                    <button
                                      type="button"
                                      className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex`}
                                      onClick={() => setIsAddingScreen(false)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className={`relative shadow rounded-md ml-2 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white disabled:bg-blue-500 disabled:border-blue-500`}
                                      onClick={handleSubmitNewTVCode}
                                      // onClick={submitAddScreen}
                                      disabled={!screenCode.addScreenCode || loadingAddScreen}
                                    >
                                      Submit code
                                      {loadingAddScreen && 
                                          <div >
                                              <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                              </svg>
                                          </div>
                                        }
                                    </button>
                                  </div>
                              </motion.div>
                              }
                            {isAddingScreen && screenCodeSuccessful &&
                              <motion.div
                                key='addScreenNameDiv'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className='flex flex-col mt-8'
                                >
                                  <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                    Enter new screen name
                                  </span>
                                  <div className='pt-2'>
                                    <GeneralFormInput  label='Screen name' type='text' placeholder='e.g. Main floor screen'  name='screenName' onChange={handleScreenCodeChange} />
                                  </div>  
                                  <div className={`flex items-center mt-3`}>
                                    <button
                                      type="button"
                                      className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex`}
                                      onClick={() => setScreenCodeSuccessful(false)}
                                    >
                                      Back 
                                    </button>
                                    <button
                                      type="button"
                                      className={`relative shadow rounded-md ml-2 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white disabled:bg-blue-500 disabled:border-blue-500`}
                                      onClick={submitNewName}
                                      disabled={!screenCode.screenName || loadingScreenName}
                                    >
                                      Submit Name
                                      {loadingScreenName && 
                                          <div >
                                              <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                              </svg>
                                          </div>
                                        }
                                    </button>
                                  </div>
                              </motion.div>
                              }
                              {!isAddingScreen && !screenCodeSuccessful &&
                                <motion.div
                                  key='addScreenBtnDiv'
                                  initial={{ y: 10, opacity: 0 }}
                                  animate={{ y: 0, opacity: 1 }}
                                  exit={{ y: -10, opacity: 0 }}
                                  transition={{ duration: 0.2 }}
                                >
                                  <button className={`text-sm text-blue-500 italic ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => setIsAddingScreen(true)}>
                                    Add Screen
                                  </button>  
                                </motion.div>
                              }
                          </div>
                        </div>
                    </motion.div>


                  </AnimatePresence>
                  <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={sendScreenUrl}
                        disabled={urlLink.link === 0 || loading || Object.keys(selectedScreen).length === 0}
                      >
                        Send Link
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SendUrlLinkModal